<template>
  <div class="account">
    <div class="header">
      <a @click="backtrack()"
        ><img class="return" src="/static/img/app/account/return.png" alt=""
      /></a>
      <p class="title">{{ $t("share.account4") }}</p>
    </div>

    <div class="content" :style="{ height: index === 3 ? '88%' : '76%' }">
      <div class="step1" v-show="index === 1">
        <p class="content_title">{{ $t("share.account2") }}</p>
        <div class="account_button btn1" @click="toIndex(2)">
          <img src="/static/img/app/account/ACCOunt.png" alt="" />
          <p>{{ $t("share.account3") }}</p>
        </div>
        <div class="account_button" @click="toIndex(4)">
          <img src="/static/img/app/account/CONTACTUS.png" alt="" />
          <p>{{ $t("share.account21") }}</p>
        </div>
      </div>

      <div class="step2" v-show="index === 2">
        <div class="step2_title">
          <p>{{ $t("share.account3") }}</p>
        </div>
        <div class="step2_content">
        <div @click="toIndex(3)">
          <p>{{ $t("share.account6") }}</p>
          <img
            class="step2_arrow"
            src="/static/img/app/account/arrow.png"
            alt=""
          />
        </div>
          <img
            class="step2_line"
            src="/static/img/app/account/line.png"
            alt=""
          />
        </div>
        <div class="step2_footer"></div>
      </div>

      <div class="step3" v-show="index === 3">
        <div class="step3_title">
          <p>{{ $t("share.account7") }}</p>
        </div>
        <div class="step3_content">
          <p class="h1">{{ $t("share.account8") }}</p>
          <img
            class="step3_line"
            src="/static/img/app/account/line.png"
            alt=""
          />
          <p>{{ $t("share.account30") }}</p>
          <!-- <p class="p1">{{ $t("share.account9") }}</p> -->
          <i18n class="p1" path="share.account9" tag="p">
            <a @click="jump('link','privacyPolicy')" place="Privacy Policy">{{ $t('share.account23') }}</a>
          </i18n>

          <p class="h2">{{ $t("share.account17") }}</p>
          <img
            class="step3_line"
            src="/static/img/app/account/line.png"
            alt=""
          />
          <p class="p1">{{ $t("share.account18").replace('%s', $route.query.day) }}</p>
          <p class="p1">{{ $t("share.account24") }}</p>
          <p class="p1">{{ $t("share.account25") }}</p>
          <p class="p1">{{ $t("share.account26") }}</p>
          <p class="p1">{{ $t("share.account27") }}</p>
          <p class="p1">{{ $t("share.account28") }}</p>
          <p class="p1">{{ $t("share.account29") }}</p>
          <p :style="{opacity: ifSubmit ? '0.5' : ''}">
              <img @click="dealTick()"
              class="checkbox"
              src="/static/img/app/account/frame_small.png"
              alt=""
            />
            <img @click="dealTick()"
              v-show="showTick"
              class="tick"
              src="/static/img/app/account/Tick.png"
              alt=""
            />
            <span>{{ $t("share.account10") }}</span>
          </p>
          <br>
          <p>{{ $t("share.account19") }}</p>
          <br>
          <P class="contact_email">
            <span class="symbol">*</span>{{ $t("share.account11") }}:
            <span v-if="ifSubmit">{{ $route.query.email }}</span>
            <input v-else v-model="email" class="email" type="text"/>
          </P>
          <div class="tips">
          {{ tips }}
          <!-- <p>{{ $t('share.account12') }}</p>
          <p style="display:none">{{ $t('share.account13') }}</p>
          <p style="display:none">{{ $t('share.account14') }}</p> -->
        </div>
        </div>
        <div class="step3_footer"></div>
        
        <div class="button" @click="submit()" :class="{ ifSubmit: ifSubmit }">
          <p>
            <span class="p" v-if="ifSubmit">{{ $t("share.account16") }}</span>
            <span class="p" v-else>{{ $t("share.account15") }}</span>
          </p>
        </div>
      </div>

      <div class="step4" v-show="index === 4">
        <div class="step4_title">
          <p>{{ $t("share.account21") }}</p>
        </div>
        <div class="step4_content">
          <p class="first">{{ $t("share.account20") }}</p>
          <div class="app">
            <img
              class="step4_line"
              src="/static/img/app/account/line.png"
              alt=""
            />
            <p @click="jump('app', 'whatsapp')">
              <img
                class="icon"
                src="/static/img/app/account/whatapp.png"
                alt=""
              />WhatsApp: +852 9171 0467
              <img
                class="step4_arrow"
                src="/static/img/app/account/arrow.png"
                alt=""
              />
            </p>
          </div>
          <div class="app">
            <img
              class="step4_line"
              src="/static/img/app/account/line.png"
              alt=""
            />
            <p @click="jump('app', 'facebook')">
              <img
                class="icon"
                src="/static/img/app/account/fb.png"
                alt=""
              />Facebook: @PokerGlory
              <img
                class="step4_arrow"
                src="/static/img/app/account/arrow.png"
                alt=""
              />
            </p>
          </div>
          <div class="app">
            <img
              class="step4_line"
              src="/static/img/app/account/line.png"
              alt=""
            />
            <p @click="jump('app', 'email')">
              <img
                class="icon"
                src="/static/img/app/account/email.png"
                alt=""
              />CS Email: cs@thepokerglory.com
              <img
                class="step4_arrow"
                src="/static/img/app/account/arrow.png"
                alt=""
              />
            </p>
          </div>
          <div class="app">
            <img
              class="step4_line"
              src="/static/img/app/account/line.png"
              alt=""
            />
            <p @click="jump('app', 'twitter')">
              <img
                class="icon"
                src="/static/img/app/account/tw.png"
                alt=""
              />Twitter: @poker_glory
              <img
                class="step4_arrow"
                src="/static/img/app/account/arrow.png"
                alt=""
              />
            </p>
          </div>
          <div class="app">
            <img
              class="step4_line"
              src="/static/img/app/account/line.png"
              alt=""
            />
            <p @click="jump('app', 'discord')">
              <img
                class="icon"
                src="/static/img/app/account/discord.png"
                alt=""
              />Discord: https://discord.gg/abzGXrkCYd
              <img
                class="step4_arrow"
                src="/static/img/app/account/arrow.png"
                alt=""
              />
            </p>
          </div>
        </div>
        <div class="step4_footer"></div>
      </div>
    </div>

    <div class="footer" v-show="index === 1 || index === 2 || index === 4">
      <div class="terms">
        <a class="privacy" @click="jump('link', 'privacyPolicy')">{{
          $t("share.account23")
        }}</a>
        <a @click="jump('link', 'termsOfService')">{{
          $t("share.account22")
        }}</a>
      </div>
      <a @click="reload()"
        ><img class="logo" src="/static/img/app/account/LOGO.png" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "account",
  data() {
    return {
      index: 1,
      showTick: false,
      tips: "",
      email: "",
      ifSubmit: false,
      // style: {}
    };
  },
  mounted() {
    this.$i18n.locale = this.$route.query.lang || 'en';
    if(this.$route.query.email) {
      this.ifSubmit = true
      this.showTick = true
    }
    this.index = parseInt(this.$route.query.index) || 1

    // fix
    // const winHeight = window.innerHeight;
    // window.onresize = () => {
    //     var thisHeight = window.innerHeight;
    //     if (winHeight - thisHeight > 50) {
    //         this.style = {
    //           position: "fixed",
    //           left: "0px",
    //           top: "0px"
    //         }
    //     } else {
    //       this.style = {}
    //     }
    // }
  },
  methods: {
    toIndex(index) {
      this.index = index
      this.$router.push({
        name: 'app-account',
        query: {...this.$route.query, index:index}
      })
    },
    dealTick() {
      if(this.ifSubmit) {
        return 
      }

      this.showTick = !this.showTick
    },
    reload() {
      window.location.reload();
    },
    backtrack() {
      if (this.index == 2) {
        this.toIndex(1)
      } else if (this.index == 3) {
        this.toIndex(2)
      } else if (this.index == 4) {
        this.toIndex(1)
      } else {
        this.jump("back", "");
      }
    },
    jump(type, url) {
      this.$cocosUtil.sendMsg({
        type: type,
        name: url,
      });
    },
    submit() {
      if(this.ifSubmit) {
        return 
      }

      if(this.$route.query.day < 7) {
        this.tips = this.$t("share.account12");
        return 
      }
      if (this.showTick == false) {
        this.tips = this.$t("share.account13");
        return;
      } else {
        this.tips = "";
      }
      if (this.email == "") {
        this.tips = this.$t("share.account14");
        return;
      }
      if (this.email != "") {
        var reg =
          /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
        //调用正则验证test()函数
        const isok = reg.test(this.email);
        if (!isok) {
          this.tips = this.$t("share.account14");
          return;
        }
      }

      axios
        .post("https://api.thepokerglory.com/open_api/apply_del_account", {
          uid: parseInt(this.$route.query.uid),
          email: this.email,
          lang: this.$route.query.lang,
        })
        .then(() => {
          this.$router.push({
            name: 'app-account',
            query: {...this.$route.query, email: this.email}
          })
          this.ifSubmit = true
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss" scoped>
*::-webkit-scrollbar {
  display: none;
}
.account * {
  font-family: "Source Sans Pro";
}
.account {
  position: relative;
  width: 100%;
  height: 100%;
  .header {
    position: relative;
    width: 100%;
    height: 12%;
    background-color: #0b47a3;
    text-align: center;
    /* display: flex;
      justify-content: center;
      align-items: center; */
    .return {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
    }
    .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5vw;
      font-family: PingFang-SC-Bold;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .content {
    position: relative;
    height: 76%;
    width: 100%;
    background-image: url(/static/img/app/account/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    .step1 {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      .content_title {
        font-family: "Source Sans Pro Black";
        letter-spacing: -1px;
        text-align: center;
        color: #ffffff;
        font-size: 5vw;
        font-weight: 400;
        margin-bottom: 3vw;
        margin-top: 4vw;
        text-shadow: 1px 2px black;
        -webkit-text-stroke: 1px black;
      }
      .account_button {
        position: relative;
        width: 40vw;
        height: 7vw;
        margin: 0 auto;
        background-image: url(/static/img/app/account/home_btn.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          height: 50%;
          margin-left: 10%;
          margin-right: 5%;
          margin-bottom: 1%;
        }
        p {
          font-family: "Source Sans Pro Black";
          color: #fff;
          font-size: 2.5vw;
          -webkit-text-stroke: 1px black; 
          text-shadow: 1px 1px black;
          line-height: 2.5vw;
          margin-bottom: 1%;
          // text-shadow: 0.7px 1.5px black;
        }
      }
      .btn1 {
        margin-bottom: 3vw;
      }
    }
    .step2 {
      position: relative;
      width: 60%;
      height: 80%;
      margin: 0 auto;
      top: 10%;
      .step2_title {
        position: relative;
        width: 100%;
        height: 6vw;

        background-image: url(/static/img/app/account/frame1.png);
        background-size: 100% auto;
        p {
          position: absolute;
          font-family: "Source Sans Pro Black";
          left: 5%;
          top: 50%;
          padding-bottom : 1vw;
          font-size: 2.5vw;
          transform: translate(0, -50%);
          color: #ffffff;
          text-shadow: 1px 1px black;
          -webkit-text-stroke: 1px black;
        }
      }
      .step2_content {
        position: relative;
        width: 100%;
        height: 80%;

        background-image: url(/static/img/app/account/frame2.png);
        background-size: 100% auto;
        background-repeat: repeat;
        p {
          position: absolute;
          padding-left: 5%;
          width: 100%;
          top: 5%;
          font-size: 2vw;
          color: #666666;
        }
        .step2_arrow {
          position: absolute;
          right: 5%;
          width: 1vw;
          top: 8%;
        }
        .step2_line {
          position: absolute;
          top: 20%;
          left: 5%;
          width: 90%;
          height: 1px;
        }
      }
      .step2_footer {
        position: relative;
        width: 100%;
        height: 1vw;

        background-image: url(/static/img/app/account/frame3.png);
        background-size: 100% auto;
        background-position: center bottom;
      }
    }
    .step3 {
      position: relative;
      width: 90%;
      height: 65%;
      margin: 0 auto;
      top: 4%;
      .step3_title {
        position: relative;
        width: 100%;
        height: 7vw;
        background-image: url(/static/img/app/account/acconut_frame1.png);
        background-size: 100% auto;
        p {
          position: absolute;
          width: 100%;
          font-size: 3vw;
          padding-bottom: 1vw;
          font-family: "Source Sans Pro Black";
          text-align: center;
          top: 50%;
          // left: 50%;
          transform: translate(0, -50%);
          color: #ffffff;
          text-shadow: 1px 1px black;
          -webkit-text-stroke: 1px black;
        }
      }
      .step3_content {
        position: relative;
        overflow-y: auto;
        width: 100%;
        height: 85%;
        padding: 0 5%;
        background-image: url(/static/img/app/account/acconut_frame2.png);
        background-size: 100% auto;
        background-repeat: repeat;
        p {
          color: #666666;
          font-size: 1.5vw;
        }
        .p1 {
          margin-bottom: 2vw;
        }
        a {
          color: #2d7cf4;
        }
        .h1 {
          font-size: 2.5vw;
          color: #000000;
          font-weight: bold;
          margin-bottom: -1vw;
        }
        .h2 {
          color: #000000;
          font-weight: bold;
          margin-bottom: -1vw;
        }
        .step3_line {
          width: 100%;
          height: 1px;
        }
        .tick {
          width: 1.5vw;         
          position: absolute;
          left: 4.7vw;
          margin-top: 0.5vw;
        }
        .checkbox {
          vertical-align: bottom;
          width: 2vw;
          background-color: #ffffff;
          margin-right: 2px;
          // background-image: url(/static/img/app/account/frame_small.png);
        }
        .contact_email {
          // margin-bottom: 5vw;
          font-size: 2.5vw;
          font-weight: bold;
          color: #000000;
          .symbol {
            color:#ff0004;
            vertical-align: middle;
            margin-right: 1vw;
          }
          .email {
            margin-left: 1vw;
            padding-left: 1vw;
            width: 45%;
            height: 6vw;
            border: solid;
            border-radius: 5px;
            border-width: 1px;
            outline-color: #2079f9;
            border-color: #cccccc;
            background-color: #ffffff;
          }
        }
      }
      .step3_footer {
        position: relative;
        width: 100%;
        height: 1vw;

        background-image: url(/static/img/app/account/acconut_frame3.png);
        background-size: 100% auto;
        background-position: center bottom;
      }
      .tips {
        color: #ff0004;
        font-size: 1.5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1vw 0 4vw 0;
      }
      .button {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        background-size: 100% 100%;
        // height: 8vw;
        // width: 50vw;
        background-image: url(/static/img/app/account/acconut_btn1.png);
        bottom: -13vw;
        border-radius: 6px;
        padding: 1.5vw 3vw 2vw 3vw;
        .p {
          font-family: "Source Sans Pro Black";
          color: #ffffff;
          font-size: 2.5vw;
          line-height: 2vw;
          white-space: nowrap;
        }
      }
      .ifSubmit {
        background-image: url(/static/img/app/account/acconut_btn2.png);
        color: #fff;
      }
    }
    .step4 {
      position: relative;
      width: 60%;
      height: 90%;
      margin: 0 auto;
      top: 5%;
      .step4_title {
        position: relative;
        width: 100%;
        height: 6vw;
        background-image: url(/static/img/app/account/frame1.png);
        background-size: 100% auto;
        p {
          position: absolute;
          left: 7%;
          top: 50%;
          font-size: 2.5vw;
          padding-bottom: 1vw;
          transform: translate(0, -50%);
          color: #ffffff;
          font-family: "Source Sans Pro Black";
          text-shadow: 1px 1px black;
          -webkit-text-stroke: 1px black;
        }
      }
      .step4_content {
        position: relative;
        width: 100%;
        max-height: 80%;
        // padding: 0 5%;
        background-image: url(/static/img/app/account/frame2.png);
        background-size: 100% auto;
        background-repeat: repeat;
        overflow-y: auto;

        p {
          position: relative;
          padding-left: 7%;
          padding-right: 7%;
          font-size: 1.5vw;
          color: #666666;
          width: 100%;
          padding-top: 1px;
        }
        .first {
          position: relative;
          padding-left: 7%;
          font-size: 2vw;
          // padding-top: 0.5vw;
          margin-bottom: 1vw;
        }
        .app {
          position: relative;
          width: 100%;
          height: 5vw;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .icon {
          position: relative;
          margin-right: 1vw;
          width: 2.4vw;
          vertical-align: bottom;
        }
        .step4_arrow {
          position: absolute;
          right: 7%;
          width: 1vw;
        }
        .step4_line {
          position: absolute;
          top: 0px;
          width: 99%;
          height: 1px;
          padding-left: 0.6vw;
          // margin-bottom: 1vw;
        }
      }
      .step4_footer {
        position: relative;
        width: 100%;
        height: 1vw;

        background-image: url(/static/img/app/account/frame3.png);
        background-size: 100% auto;
        background-position: center bottom;
      }
    }
  }
  .footer {
    position: relative;
    height: 12%;
    width: 100%;
    background-color: #0b47a3;
    .terms {
      position: absolute;
      left: 5%;
      top: 50%;
      transform: translate(0, -50%);
    }
    .terms a {
      font-family: PingFang-SC-Bold;
      font-size: 1vw;
      color: #ffffff;
      text-decoration: underline;
    }
    .privacy {
      margin-right: 5vw;
    }
    .logo {
      position: absolute;
      height: 65%;
      right: 3vw;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}
</style>
